<script setup lang="ts">
import { Method, PageProps, Progress } from "@inertiajs/core";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import { Link } from "@inertiajs/vue3";
import { twMerge } from "tailwind-merge";
import { useAttrs } from "vue";
import AppIcon, {
    IconColor,
    IconName,
    IconSize
} from "@/Components/Shared/icon/AppIcon.vue";
import { buttonClasses } from "@/Components/Shared/button";

export type LinkColor =
    | "primary"
    | "default"
    | "currentColor"
    | "white"
    | "red"
    | "grayLight";
export type LinkVariant =
    | "button"
    | "buttonPrimary"
    | "underlined"
    | "noUnderline"
    | "buttonSecondary"
    | "buttonXSmall"
    | "buttonSmall";
export type LinkSize = "xsmall" | "small" | "regular" | "current";
export type LinkAs = "a" | "link" | "button" | "div";

type Props = {
    as?: LinkAs;
    data?: object;
    href: string;
    method?: Method;
    headers?: object;
    onClick?: (event: MouseEvent | KeyboardEvent) => void;
    preserveScroll?: boolean | ((props: PageProps) => boolean);
    preserveState?: boolean | ((props: PageProps) => boolean) | null;
    replace?: boolean;
    only?: string[];
    onCancelToken?: (cancelToken: import("axios").CancelTokenSource) => void;
    onBefore?: () => void;
    onStart?: () => void;
    onProgress?: (progress: Progress) => void;
    onFinish?: () => void;
    onCancel?: () => void;
    onSuccess?: () => void;
    queryStringArrayFormat?: "brackets" | "indices";
    color?: LinkColor;
    size?: LinkSize;
    variant?: LinkVariant | LinkVariant[];
    icon?: IconName;
    iconSize?: IconSize;
    iconColor?: IconColor;
};
const {
    as = "link",
    data = undefined,
    method = "get",
    headers = undefined,
    onClick = undefined,
    preserveScroll = undefined,
    preserveState = undefined,
    only = undefined,
    onCancelToken = undefined,
    onBefore = undefined,
    onStart = undefined,
    onProgress = undefined,
    onFinish = undefined,
    onCancel = undefined,
    onSuccess = undefined,
    queryStringArrayFormat = undefined,
    color = "default",
    size = "current",
    variant = undefined,
    icon = undefined,
    iconColor = undefined,
    iconSize = undefined,
} = defineProps<Props>();

const attrs = useAttrs();
const classes: AntlerClasses<Props> = {
    base: "no-underline hover:underline font-medium",
    variants: {
        color: {
            default: "text-gray-800",
            primary: "text-primary",
            currentColor: "text-current hover:text-current",
            white: "text-white",
            grayLight: "text-slate-500",
            red: "text-red-800",
        },
        variant: {
            button: twMerge(
                buttonClasses?.base,
                buttonClasses.variants?.size?.regular,
                buttonClasses.variants?.color?.default,
            ),
            buttonPrimary: twMerge(
                buttonClasses?.base,
                buttonClasses.variants?.size?.regular,
                buttonClasses.variants?.color?.primary,
            ),
            buttonSecondary: twMerge(
                buttonClasses?.base,
                buttonClasses.variants?.size?.regular,
                buttonClasses.variants?.color?.secondary,
            ),
            buttonSmall: twMerge(
                buttonClasses?.base,
                buttonClasses.variants?.size?.small,
                buttonClasses.variants?.color?.default,
            ),
            buttonXSmall: twMerge(
                buttonClasses?.base,
                buttonClasses.variants?.size?.xsmall,
                buttonClasses.variants?.color?.default,
            ),
            underlined: "underline",
            noUnderline: "no-underline hover:no-underline",
        },
        size: {
            xsmall: "text-xs",
            small: "text-sm",
            regular: "text-base",
            current: "",
        },
    },
};
const { aClass } = installAntlerComponent(
    "link",
    { color, size, variant },
    classes,
    attrs,
);
</script>
<template>
    <Link
        v-if="as === 'link'"
        :class="
            aClass({
                'inline-flex gap-2 items-center': icon,
            })
        "
        :data="data"
        :href="href"
        :method="method"
        :headers="headers"
        :preserve-scroll="preserveScroll"
        :preserve-state="preserveState"
        :replace="replace"
        :only="only"
        :on-cancel-token="onCancelToken"
        :on-before="onBefore"
        :on-start="onStart"
        :on-progress="onProgress"
        :on-finish="onFinish"
        :on-cancel="onCancel"
        :on-success="onSuccess"
        :query-string-array-format="queryStringArrayFormat"
        @click="onClick"
    >
        <AppIcon
            v-if="icon"
            :name="icon"
            :color="iconColor"
            :size="iconSize"
        />
        <slot />
    </Link>
    <a
        v-else
        :href="href"
        :class="
            aClass({
                'inline-flex gap-2 items-center': icon,
            })
        "
    >
        <AppIcon
            v-if="icon"
            :name="icon"
            :color="iconColor"
            :size="iconSize"
        />
        <slot />
    </a>
</template>
